.card {
  background-color: #e9e9e9;
  background: 50% no-repeat;
  background-size: 328px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 19px;
  height: 328px;
  width: 328px;
}

.card:hover {
  cursor: pointer;
}

.card__container {
  position: relative;
  display: flex;
  justify-content: center;
}

.card__name {
  background-color: #fff;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 700;
  margin: 16px auto 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
}

.card__image {
  background: 50% no-repeat;
  background-size: 328px;
  border-radius: 19px;
  height: 328px;
  width: 100%;
  /* object-fit: contain; */
}

.card__like-button {
  position: absolute;
  left: 65.82%;
  top: 5.5%;
  bottom: 61.68%;
  height: 15px;
  width: 18px;
}

.card__like-button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.card__unlike-button {
  position: absolute;
  left: 65.82%;
  top: 5.5%;
  bottom: 61.68%;
  height: 15px;
  width: 18px;
}

.card__unlike-button:hover {
  opacity: 0.8;
  cursor: pointer;
}

@media screen and (min-width: 375px) and (max-width: 822px) {
  .card {
    width: 164px;
    height: 164px;
  }

  .card__container {
    border-radius: 20px;
    box-sizing: border-box;
  }

  .card__image {
    height: 164px;
  }

  .card__like-button {
    margin: 10px 0 0 14px;
  }
}

@media screen and (min-width: 823px) and (max-width: 1440px) {
  .card__image {
    width: 328px;
  }

  .card__name {
    max-width: 275px;
  }
}
