.footer {
  align-items: flex-end;
  display: flex;
  font-family: "Cabinet Grotesk", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  justify-content: space-between;
  line-height: 20px;
  margin: 65px auto 0;
  max-width: 1440px;
  padding-bottom: 24px;
}

.footer__author {
  margin: 0 0 0 40px;
}

.footer__year {
  margin: 0 54px 0 0;
}

@media screen and (min-width: 823px) and (max-width: 1440px) {
  .footer {
    max-width: 768px;
    margin: 65px auto 0 auto;
    max-width: 768px;
  }

  .footer__author {
    margin: 0 0 0 20px;
  }

  .footer__year {
    margin: 0 20px 0 0;
  }
}

@media screen and (min-width: 375px) and (max-width: 822px) {
  .footer__author {
    margin: 0 0 0 10px;
  }

  .footer__year {
    margin: 0 20px 0 0;
  }
}


