.modal__container-confirm {
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0;
  display: flex;
  opacity: 1;
  justify-content: center;
  align-items: center;
}

.modal__confirm {
  background-color: #fff;
  width: 496px;
  height: 280px;
  border: 1px solid rgba(0, 0, 0.3);
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.modal__button {
  align-self: flex-end;
  background-color: transparent;
  border: none;
  display: flex;
  padding: 0;
}

.modal__container_confirm {
  text-align: center;
}

.modal__text_confirm {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  line-height: 1.2em;
}

.modal__button_confirm {
  font-size: 20px;
  font-weight: 700;
  color: #ff4d00;
  border: none;
  background-color: #fff;
  margin: 40px 0 0 0;
  padding: 0;
  width: fit-content;
}

.modal__button_confirm:hover {
  opacity: 0.6;
  cursor: pointer;
}

.modal__button_cancel {
  font-size: 20px;
  font-weight: 700;
  border: none;
  background-color: #fff;
  margin: 24px 0 0 0;
  padding: 0;
  width: fit-content;
}

.modal__button_cancel:hover {
  opacity: 0.6;
  cursor: pointer;
}

@media screen and (min-width: 375px) and (max-width: 822px) {
  .modal__confirm {
    max-width: 343px;
  }
}

/* @media screen and (min-width: 823px) and (max-width: 1400px) {
} */