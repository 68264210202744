.weather {
  position: relative;
}

.weather__temp-container {
  position: absolute;
  top: 0;
  left: 0px;
  color: white;
  font-size: 36px;
  font-weight: 700;
  padding: 20px 0 0;
  margin: 0 0 0 20px;
}

.weather__image {
  display: flex;
  width: 100%;
  width: 1360px;
  height: 80px;
}

@media screen and (min-width: 375px) and (max-width: 822px) {
  .weather {
    max-width: 768px;
  }

  .weather__image {
    border-radius: 20px;
    object-fit: none;
    object-position: 100% 0;
    height: 80px;
    left: 16px;
    top: 134px;
    width: 340px;
  }

  .weather__info {
    font-size: 36px;
  }
}

@media screen and (max-width: 320px) {
  /* .weather__image {
    width: 375px;
  } */
}

/* @media screen and (min-width: 823px) and (max-width: 1440px) {
} */
